import React, { useState, useEffect } from 'react';
import Spinner from '../Spinner/Spinner';

/*
PURPOSE: Displays ecosystem vitality metrics for an artist

USE CASES:
- Showing users the health and stage of an artist's support ecosystem
- Providing insights on the number of pulses and financial support
- Suggesting next actions based on ecosystem health
- Indicating whether artists have received their payments

DOMAIN BOUNDARIES:
- Responsible for fetching and displaying ecosystem data for a specific artist
- Does not manage user interactions beyond displaying information
- Limited to read-only visualization of ecosystem metrics

ASSUMPTIONS:
- Artist name uniquely identifies an ecosystem
- Transaction data is available in CSV format from S3
- Most recent month of activity is the key measurement period
*/

interface EcosystemVitalsProps {
  artistName: string;
}

const EcosystemVitals: React.FC<EcosystemVitalsProps> = ({ artistName }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // We'll store raw data plus derived stage & health
  const [stats, setStats] = useState({
    totalPulses: 0,
    monthlySupport: 0,
    totalSupport: 0,
    ecosystemStage: '',
    ecosystemHealth: 0, // We'll treat this as a 0–100 numeric gauge
    ecosystemHealthLabel: '', // e.g., "Healthy" / "Unhealthy"
    ecosystemAdvice: '',
    hasReceivedPayment: false // New field to track if artist has received any payments
  });

  // --------------------------------------
  // Utility functions for Stage & Health
  // --------------------------------------

  // 1) Determine the ecosystem stage
  const getEcosystemStage = (
    pulsesInLastMonth: number,
    monthlySupport: number,
    totalPulses: number,
    hasReceivedPayment: boolean
  ): string => {
    if (totalPulses === 0) {
      return 'Vision';
    }
    
    // If artist has received payment, they can reach any stage based on metrics
    if (hasReceivedPayment) {
      // Follow updated rules in order:
      //  - Resilient: monthlySupport > 150 && totalPulses > 10
      //  - Thriving: monthlySupport > 50
      //  - Coordinating: hasReceivedPayment is true (minimum level)
      if (monthlySupport > 150 && totalPulses > 10) {
        return 'Resilient';
      } else if (monthlySupport > 50) {
        return 'Thriving';
      } else {
        return 'Coordinating'; // Minimum level for artists who have received payment
      }
    } else {
      // When no payments have been received, maximum level is Coordinating
      if (pulsesInLastMonth > 1) {
        return 'Coordinating'; // Maximum level without payment
      } else if (pulsesInLastMonth === 1) {
        return 'Emerging';
      } else {
        return 'Dormant';
      }
    }
  };

  // 2) Determine if earliest pulse is less than 6 months old
  const isEarliestPulseLessThan6Months = (earliestDateStr: string): boolean => {
    if (!earliestDateStr) return false; // fallback if no date found

    // Format: YYYYMMDD
    const year = parseInt(earliestDateStr.substring(0, 4));
    const month = parseInt(earliestDateStr.substring(4, 6)) - 1; // zero-based
    const day = parseInt(earliestDateStr.substring(6, 8));

    const earliestDate = new Date(year, month, day);
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    return earliestDate >= sixMonthsAgo;
  };

  // 3) Determine textual "health" from stage & earliest date
  const getEcosystemHealthLabel = (
    stage: string,
    earliestDateStr: string,
    totalPulses: number,
    pulsesInLastMonth: number,
    hasReceivedPayment: boolean
  ): 'Healthy' | 'Unhealthy' | 'Neutral' => {
    // If artist has received payment, ecosystem is generally considered healthier
    if (hasReceivedPayment) {
      if (['Coordinating', 'Thriving', 'Resilient'].includes(stage)) {
        return 'Healthy';
      }
    }
    
    if (stage === 'Emerging') {
      // If earliest pulse < 6 months ago => Healthy
      if (isEarliestPulseLessThan6Months(earliestDateStr)) {
        return 'Healthy';
      } else {
        // If earliest pulse is older than 6 months & there's only 1 pulse in the last month => Unhealthy
        if (pulsesInLastMonth === 1) {
          return 'Unhealthy';
        }
      }
    }
    // You can define additional logic for other stages, or default to 'Neutral'
    return 'Neutral';
  };

  // 4) Map stage & health label to a 0–100 gauge
  const getEcosystemHealthGauge = (
    stage: string,
    healthLabel: string,
    hasReceivedPayment: boolean
  ): number => {
    // Modify the gauge to reflect payment status
    const paymentBonus = hasReceivedPayment ? 10 : 0;
    
    switch (stage) {
      case 'Vision':
        return 5;
      case 'Resilient':
        return 95 + paymentBonus > 100 ? 100 : 95 + paymentBonus;
      case 'Thriving':
        return 80 + paymentBonus;
      case 'Coordinating':
        return 65 + paymentBonus;
      case 'Emerging':
        if (healthLabel === 'Healthy') return 50 + paymentBonus;
        if (healthLabel === 'Unhealthy') return 25 + paymentBonus;
        return 35 + paymentBonus; // Some mid-point for "Neutral Emerging"
      case 'Dormant':
        return 15 + paymentBonus;
      default:
        return 10 + paymentBonus;
    }
  };

  const getEcosystemAdvice = (stage: string, healthLabel: string, hasReceivedPayment: boolean): string => {
    // Include payment collection information in advice that emphasizes the reciprocal relationship
      
    if (stage === 'Vision') {
      return (
        "This ecosystem is waiting to be brought to life. " +
        "By adding the first coral, you'll pioneer a foundation " +
        "that inspires others to join."
      );
    }
    if (stage === 'Emerging') {
        if (healthLabel === 'Healthy') {
        return (
            "Initial support patterns are establishing a foundation for the ecosystem. " +
            "Each pulse signals appreciation and gratitude for the artist's work. "
        );
        } else if (healthLabel === 'Unhealthy') {
        return (
            "Early support shows potential for meaningful connection. " +
            "Additional backers will strengthen the ecosystem. "
        );
        } else {
        return (
            "Recent pulse activity has opened channels of support. " +
            "Regular contributions help establish sustainable cooperation " +
            "between artist and community. "
        );
        }
    } else if (stage === 'Coordinating') {
        if (hasReceivedPayment) {
          return (
          "Corals are pulsing in coordination with the artist, creating a rhythm of exchange. " +
          "This pattern of support demonstrates growing mutual engagement. "
          );
        } else {
          return (
          "Corals are growing together, creating waves of support. " +
          "Artist engagement is required to sustain the ecosystem. "
          );
        }
    } else if (stage === 'Thriving') {
        return (
        "The exchange between community and artist is flourishing. " +
        "Regular pulses have created a diverse and strong foundation for " +
        "ongoing creative dialogue. "
        );
    } else if (stage === 'Resilient') {
        return (
        "A vibrant conversation of support flows through established trust paths. " +
        "The artist and community are engaged in a sustainable exchange " +
        "that nurtures creativity and connection. "
        );
    } else if (stage === 'Dormant') {
        return (
        "This ecosystem's conversation has temporarily paused. " +
        "New pulses will reactivate the dialogue between artist and supporters, " +
        "renewing the cycle of exchange. "
        );
    }
    return '';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const stage = process.env.REACT_APP_ENVIRONMENT || 'dev';
        const url = `https://wearecoral-public-data-${stage}.s3.ap-southeast-2.amazonaws.com/wearecoral-contribution-report.csv`;
        const response = await fetch(url);
        const text = await response.text();
        const rows = text.split('\n').slice(1); // Skip header

        // We'll count every row for the correct artist as a pulse
        let allPulsesCount = 0;
        let earliest = '99999999';
        let monthlyTotal = 0;
        let allTimeTotal = 0;
        let hasReceivedPayment = false;

        // Define interface for artist transactions
        interface ArtistTransaction {
          date: string;
          formattedDate: string;
          amount: number;
          isInLastMonth: boolean;
          paymentDistributed: string;
        }

        // For debugging - collect all transactions for this artist
        const artistTransactions: ArtistTransaction[] = [];

        // For "pulses in last month"
        let pulsesInLastMonth = 0;
        
        // Find the most recent date FOR THIS SPECIFIC ARTIST (not the entire CSV)
        let artistMostRecentDate = '00000000';
        rows.forEach((row) => {
          if (!row.trim()) return;
          const [dateStr, , , name] = row.split(',');
          if (name && name.toLowerCase() === artistName.toLowerCase()) {
            if (dateStr > artistMostRecentDate) {
              artistMostRecentDate = dateStr;
            }
          }
        });

        console.log(`Artist most recent date: ${artistMostRecentDate}`);

        // Parse the most recent date
        const recentYear = parseInt(artistMostRecentDate.substring(0, 4));
        const recentMonth = parseInt(artistMostRecentDate.substring(4, 6));
        const recentDay = parseInt(artistMostRecentDate.substring(6, 8));
        
        // Calculate the one month before date
        let oneMonthBeforeYear = recentYear;
        let oneMonthBeforeMonth = recentMonth - 1;
        
        // Handle month rollover
        if (oneMonthBeforeMonth === 0) {
          oneMonthBeforeMonth = 12;
          oneMonthBeforeYear--;
        }
        
        // Get the last day of the previous month to handle month length differences
        // This handles cases like Mar 31 -> Feb 28/29 correctly
        let oneMonthBeforeDay = recentDay;
        const lastDayOfPrevMonth = new Date(oneMonthBeforeYear, oneMonthBeforeMonth, 0).getDate();
        if (oneMonthBeforeDay > lastDayOfPrevMonth) {
          oneMonthBeforeDay = lastDayOfPrevMonth;
        }
        
        // Create the date string in YYYYMMDD format
        // Add 1 day to make it exclusive (so Jan 28 is not included when going back from Feb 28)
        const oneMonthAgoDate = new Date(oneMonthBeforeYear, oneMonthBeforeMonth - 1, oneMonthBeforeDay + 1);
        const oneMonthBeforeDateStr = 
          `${oneMonthAgoDate.getFullYear()}${(oneMonthAgoDate.getMonth() + 1).toString().padStart(2, '0')}${oneMonthAgoDate.getDate().toString().padStart(2, '0')}`;
        
        console.log(`Most recent date string: ${artistMostRecentDate}`);
        console.log(`One month before string: ${oneMonthBeforeDateStr}`);

        // Process transactions
        rows.forEach((row) => {
          if (!row.trim()) return;
          const [dateStr, , amount, name, , , paymentDistributed] = row.split(',');

          if (name && name.toLowerCase() === artistName.toLowerCase()) {
            allPulsesCount += 1;

            if (dateStr < earliest) {
              earliest = dateStr;
            }

            const floatAmount = parseFloat(amount || '0');
            allTimeTotal += floatAmount;

            // Check if this artist has received any payments
            if (paymentDistributed && paymentDistributed.trim().toLowerCase() === 'yes') {
              hasReceivedPayment = true;
            }

            // Use a strict "after" comparison to make the range exclusive at the start
            // For Feb 28 -> Jan 29 (not including Jan 28)
            const isInLastMonth = dateStr > oneMonthBeforeDateStr && 
                                 dateStr <= artistMostRecentDate;
            
            // For debugging - also create Date objects to see the difference
            const year = parseInt(dateStr.substring(0, 4));
            const month = parseInt(dateStr.substring(4, 6)) - 1;
            const day = parseInt(dateStr.substring(6, 8));
            const transactionDate = new Date(year, month, day);
            
            // Collect for debugging
            artistTransactions.push({
              date: dateStr,
              formattedDate: transactionDate.toISOString(),
              amount: floatAmount,
              isInLastMonth,
              paymentDistributed: paymentDistributed || 'unknown'
            });

            if (isInLastMonth) {
              monthlyTotal += floatAmount;
              pulsesInLastMonth += 1;
            }
          }
        });

        // Print all transactions for this artist for debugging
        console.log(`Found ${artistTransactions.length} total pulses for ${artistName}:`);
        artistTransactions.forEach((tx, index) => {
          console.log(`Pulse #${index + 1}: Date=${tx.date}, Formatted=${tx.formattedDate}, Amount=${tx.amount}, In last month=${tx.isInLastMonth}, Payment distributed=${tx.paymentDistributed}`);
        });
        console.log(`Pulses in last month: ${pulsesInLastMonth}`);
        console.log(`Monthly total: ${monthlyTotal}`);
        console.log(`Earliest date: ${earliest}`);
        console.log(`Has received payment: ${hasReceivedPayment}`);

        // Derive Stage - now with payment status parameter
        const stageLabel = getEcosystemStage(
          pulsesInLastMonth,
          monthlyTotal,
          allPulsesCount,
          hasReceivedPayment
        );

        // Derive Health Label - now with payment status parameter
        const healthLabel = getEcosystemHealthLabel(
          stageLabel,
          earliest,
          allPulsesCount,
          pulsesInLastMonth,
          hasReceivedPayment
        );

        // Derive a numeric gauge 0–100 - now with payment status parameter
        const healthGauge = getEcosystemHealthGauge(stageLabel, healthLabel, hasReceivedPayment);

        // Advice text - now with payment status parameter
        const adviceText = getEcosystemAdvice(stageLabel, healthLabel, hasReceivedPayment);

        setStats({
          totalPulses: allPulsesCount,
          monthlySupport: Math.floor(monthlyTotal),
          totalSupport: Math.floor(allTimeTotal),
          ecosystemStage: stageLabel,
          ecosystemHealth: healthGauge,
          ecosystemHealthLabel: healthLabel,
          ecosystemAdvice: adviceText,
          hasReceivedPayment
        });

        setLoading(false);
      } catch (err) {
        setError('Failed to load ecosystem data');
        setLoading(false);
      }
    };

    if (artistName) {
      fetchData();
    }
  }, [artistName]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-32">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-sm">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-coral-blue/90 rounded-lg p-6">
      <h4 className="text-sm font-bold text-coral-white mb-4 uppercase tracking-wider">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
          Ecosystem Vitals
        </span>
      </h4>
      <div className="flex gap-8 items-center">
        {/* Total Pulses */}
        <div>
          <p className="text-2xl font-bold text-white">{stats.totalPulses}</p>
          <p className="text-sm text-white/80">Pulses</p>
        </div>

        {/* Monthly Support */}
        <div>
          <p className="text-2xl font-bold text-white">${stats.monthlySupport}</p>
          <p className="text-sm text-white/80">Monthly</p>
        </div>

        {/* Total Support */}
        <div>
          <p className="text-2xl font-bold text-white">${stats.totalSupport}</p>
          <p className="text-sm text-white/80">Total</p>
        </div>

        {/* Stage & Health Gauge */}
        <div className="border-l border-coral-black/20 pl-8">
          <p className="text-sm font-medium text-white mb-1">
            Stage: {stats.ecosystemStage}
          </p>
          <div className="w-24 bg-gray-200 rounded-full h-1.5">
            <div
              className="bg-gradient-to-r from-coral-pink to-coral-orange h-1.5 rounded-full"
              style={{ width: `${stats.ecosystemHealth}%` }}
            ></div>
          </div>
          <p className="text-xs text-white/70 mt-1">
            Health: {stats.ecosystemHealthLabel}
          </p>
        </div>
      </div>

      {/* Artist Collecting Status Section */}
      {stats.ecosystemStage !== 'Vision' && (
        <div className="mt-4 flex items-center">
          <span className="text-sm font-medium text-white mr-2">Artist Engagement:</span>
          <div className={`w-3 h-3 rounded-full ${stats.hasReceivedPayment ? 'bg-green-500' : 'bg-red-500'}`}></div>
          <span className="text-xs text-white/70 ml-2">
            {stats.hasReceivedPayment ? 'Actively receiving contributions' : 'Yet to collect contributions'}
          </span>
        </div>
      )}

      {/* Advice / Next Steps */}
      {stats.ecosystemAdvice && (
        <div className="mt-4 text-white text-sm">
          <p>{stats.ecosystemAdvice}</p>
        </div>
      )}
    </div>
  );
};

export default EcosystemVitals;